import { Box, BoxProps, Grid, Stack, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { RouteParams } from "@router/route-params";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { sphereColors } from "@styles/common-colors";
import { SecuredRoute } from "@router/secured-route";
import { SdbProject } from "@custom-types/project-types";
import { getPreservedQueryParams, useAppParams } from "@router/router-helper";
import {
  TAB_WITH_NO_UNDERLINE,
  DEFAULT_TAB_UNDERLINE,
} from "@styles/common-styles";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { FaroBetaBadge } from "@components/common/faro-beta-badge";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";
import { RequiredPermissionProjectLevelName } from "@utils/permission-control/project-permission-control-types";
import { FaroPopover } from "@faro-lotv/flat-ui";
import { SendFeedbackDialog } from "@components/header/send-feedback-dialog";
import { FaroTextButton } from "@components/common/faro-text-button";
import { TEAMS_DISPLAY_NAME } from "@src/constants/team-constants";

type PopoverTab = {
  /** The description of popover */
  description: ReactNode;

  /** The title of popover */
  title: ReactNode;

  /** Should show the feedback button */
  shouldShowFeedbackButton: boolean;
};

export interface TabProps<K, RequiredT> {
  /** The label shown for a tab */
  label: ReactNode;

  /** The path that clicking on the tab will eventually go */
  route: K;

  /** The content to show for the tab */
  content: (
    requiredAttribute: Exclude<RequiredT, undefined | null>
  ) => ReactNode;

  /**
   * Content to show while the tab is loading.
   * This is normally used when the permissions to fetch a tab are fetched asynchronously,
   * otherwise the SecuredRoute component shows an empty page meanwhile.
   */
  loadingContent: ReactNode;

  /** List of action buttons shown for the tab */
  actionButtons?: JSX.Element;

  /** Whether the tab should be hidden */
  isHidden?: boolean;

  /**
   * Defines the required company role that the user should have,
   * if the user matches any of the provided requiredCompanyRoles the component
   * will give the user access, otherwise will redirect to the forbidden page.
   */
  requiredRoleCompanyLevel?: RequiredRoleCompanyLevelName;

  /**
   * Defines the required project permission that the user should have in the selected project,
   * if the user matches any of the provided requiredProjectPermission the component
   * will give the user access, otherwise will redirect to the forbidden page.
   */
  requiredPermissionProjectLevel?: RequiredPermissionProjectLevelName;

  /**
   * A name that can be used to track the tab that was opened. If no trackingTabName is provided,
   * the label will be used as the tracking name. This is useful when the label is a
   * component and not a simple string.
   */
  trackingTabName?: string;

  /** Whether the tab is a beta feature and should show a beta badge attached to its label */
  isBetaFeature?: boolean;

  /** When do you want to open a popover on tab */
  popover?: PopoverTab;
}

interface Props<K, RequiredT> {
  /** The tab that is selected */
  selectedTab: K;

  /** Data needed for each tab */
  tabs: TabProps<K, RequiredT>[];

  /**
   * the value used to identify each Nav link of the sideBar by a unique id
   * this value is used for our automation framework playwright
   */
  dataTestId?: string;

  /** The selected project (if applies), useful to identify the user's permission levels */
  selectedProject?: SdbProject | null;

  /**
   * Defines a required attribute that it is needed for the tab to be loaded.
   * While this attribute is undefined or null, the tab will show the loading content.
   */
  requiredAttribute: RequiredT;

  /** Whether the data required to display the tabs is loading */
  isLoading?: boolean;

  /**
   * The name of the event to track opening tab events.
   * In the same page all opening of a tab is tracked with the same event name,
   * and the tab name is sent as a prop.
   */
  trackingEventPageName: OpenTabEvents;
}

/**
 * A general component which generates tabs that are changing the routes
 */
export function TabsWithRoutes<
  T extends keyof RouteParams,
  K extends RouteParams[T],
  RequiredT
>({
  selectedTab,
  tabs,
  dataTestId,
  trackingEventPageName,
  selectedProject = null,
  requiredAttribute,
  isLoading = false,
}: Props<K, RequiredT>): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { memberId } = useAppParams();
  const { hasUserPermissionCompanyLevel } = useHasUserValidRoleCompanyLevel();
  const { hasUserPermissionProjectLevel } =
    useHasUserValidPermissionProjectLevel();

  const popoverRefs = useRef<{
    [key: string]: HTMLDivElement | HTMLAnchorElement | null;
  }>({});

  const [activePopoverIndex, setActivePopoverIndex] = useState<number | null>(
    null
  );
  const [shouldShowGiveFeedback, setShouldShowGiveFeedback] =
    useState<boolean>(false);

  // Get the current location and preserved query parameters
  const location = useLocation();
  const preservedSearch = getPreservedQueryParams(location.search);

  // Returns the content of the selected tab
  const selectedTabContent = useMemo(() => {
    const tabToShow = tabs.find(({ route }) => route === selectedTab);

    /**
     * Checks whether the required attribute is defined and not null.
     */
    function isRequiredAttributeDefined<RequiredT>(
      requiredAttribute: RequiredT
    ): requiredAttribute is Exclude<RequiredT, undefined | null> {
      return requiredAttribute !== undefined && requiredAttribute !== null;
    }

    /** Show the loading content while the required attribute is not defined yet or if the data is loading */
    if (!isRequiredAttributeDefined(requiredAttribute) || isLoading) {
      return tabToShow?.loadingContent;
    }

    return (
      <SecuredRoute
        requiredRoleCompanyLevel={tabToShow?.requiredRoleCompanyLevel}
        requiredPermissionProjectLevel={
          tabToShow?.requiredPermissionProjectLevel
        }
        isHidden={tabToShow?.isHidden}
        loadingContent={tabToShow?.loadingContent}
      >
        {tabToShow?.content(requiredAttribute)}
      </SecuredRoute>
    );
  }, [tabs, requiredAttribute, isLoading, selectedTab]);

  // Returns the action buttons of the selected tab
  const selectedTabActionButtons = useMemo(() => {
    return tabs.find(({ route }) => route === selectedTab)?.actionButtons;
  }, [tabs, selectedTab]);

  /**
   * Returns whether a tab is hidden based on the disabled property
   * or on the user's permissions if provided.
   *
   * @returns True if the tab should be hidden.
   */
  function isTabHidden(tab: TabProps<K, RequiredT>): boolean {
    // Never hide the selected tab because it was probably open from a deep link.
    // Otherwise there is an error thrown in the console that the route does not exist.
    // If user does not have access to the tab it will be redirected to the forbidden page
    // by the secured route component.
    if (selectedTab === tab.route) {
      return false;
    }

    if (tab.isHidden) {
      return true;
    }

    if (
      tab.requiredRoleCompanyLevel &&
      !hasUserPermissionCompanyLevel({
        roleName: tab.requiredRoleCompanyLevel,
        memberId,
      })
    ) {
      return true;
    }
    if (
      tab.requiredPermissionProjectLevel &&
      !hasUserPermissionProjectLevel({
        permissionName: tab.requiredPermissionProjectLevel,
        project: selectedProject,
      })
    ) {
      return true;
    }
    return false;
  }

  return (
    <Box component="div" sx={{ width: "100%" }} data-testid={dataTestId}>
      <Box component="div" sx={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          scrollButtons
          component={GetTabTitlesWithActions}
          selectedTabActionButtons={selectedTabActionButtons}
          TabIndicatorProps={{
            sx: {
              backgroundColor: sphereColors.blue500,
              height: "3px",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              ref={(el) => (popoverRefs.current[index] = el)}
              onMouseEnter={() => {
                if (tab.popover) {
                  setActivePopoverIndex(index);
                }
              }}
              onMouseLeave={() => {
                if (tab.popover) {
                  setActivePopoverIndex(null);
                }
              }}
              disableRipple
              key={tab.route}
              component={Link}
              label={
                <Stack direction="row" spacing={1}>
                  {tab.label}
                  {tab.isBetaFeature && <FaroBetaBadge bottom="10px" />}
                  {tab.popover && (
                    <FaroPopover
                      dark
                      open={activePopoverIndex === index}
                      anchorEl={popoverRefs.current[index]}
                      onClose={() => setActivePopoverIndex(null)}
                      closeOnClickOutside={false}
                      title={tab.popover.title}
                      description={
                        <>
                          {tab.popover.description}
                          {tab.popover.shouldShowFeedbackButton && (
                            <Stack alignItems={"flex-end"}>
                              <FaroTextButton
                                onClick={() => {
                                  setShouldShowGiveFeedback(true);
                                  setActivePopoverIndex(null);
                                }}
                                sx={{
                                  fontWeight: "bold",
                                  "&:focus": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                              >
                                Give feedback
                              </FaroTextButton>
                            </Stack>
                          )}
                        </>
                      }
                    />
                  )}
                </Stack>
              }
              value={tab.route}
              relative="path"
              to={{
                pathname: `../${tab.route}`,
                search: preservedSearch,
              }}
              sx={{
                // Use display none to hide tabs, that way we don't need to repaint all tabs
                display: isTabHidden(tab) ? "none" : undefined,
                padding: 0,
                fontSize: "14px",
                letterSpacing: "-0.2px",
                marginRight: "20px",
                textTransform: "none",
                minWidth: "30px",
                fontWeight: selectedTab === tab.route ? "bold" : "lighter",
                "&.MuiTab-root": {
                  color:
                    selectedTab === tab.route
                      ? sphereColors.blue500
                      : sphereColors.gray800,
                },
                "&:hover": {
                  color: sphereColors.blue500,
                  borderBottom: `1px solid ${sphereColors.blue500}`,
                  // Add a top padding to avoid the tab content from jumping
                  paddingTop: "1px",
                },
              }}
              onClick={() => {
                trackEvent({
                  name: trackingEventPageName,
                  props: {
                    tab: getTrackingTabName(tab.label, tab.trackingTabName),
                  },
                });
              }}
            />
          ))}
        </Tabs>
      </Box>
      {selectedTabContent}

      {/* /** This dialog is used for submitting feedback about teams (user groups) */}
      <SendFeedbackDialog
        shouldShowFeedbackDialog={shouldShowGiveFeedback}
        setShouldShowFeedbackDialog={setShouldShowGiveFeedback}
        title="How can we improve teams?"
        featureName={`${TEAMS_DISPLAY_NAME} (User Groups)`}
      />
    </Box>
  );
}

interface GetTabTitlesWithActionsProps {
  /** List of action buttons to show for specific tab */
  selectedTabActionButtons?: JSX.Element;

  /** The props of the Box properties that is passed from TabsWithRoutes directly */
  props?: BoxProps;
}

const HEIGHT_OF_COMPONENT_WITHOUT_BREAKS = 52;

/**
 * Add action buttons at the right of a specific tab
 */
function GetTabTitlesWithActions({
  selectedTabActionButtons,
  ...props
}: GetTabTitlesWithActionsProps): JSX.Element {
  const [isWrapped, setIsWrapped] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * This useEffect initializes a ResizeObserver to dynamically adjust the layout of the tab titles and action
   * buttons based on the container's height. If the height exceeds a predefined threshold
   * (HEIGHT_OF_COMPONENT_WITHOUT_BREAKS), it indicates that the buttons have wrapped onto a new line, prompting CSS
   * adjustments to ensure proper spacing and styling. The observer is cleaned up when the component
   * unmounts to prevent memory leaks.
   */
  useEffect(() => {
    // Initialize ResizeObserver to monitor changes in the container's size
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      // Check if the container's height exceeds the threshold indicating a line break
      const isBreakingLines =
        entries[0].target.clientHeight > HEIGHT_OF_COMPONENT_WITHOUT_BREAKS;
      setIsWrapped(isBreakingLines);
    });

    observer.observe(container);

    return () => {
      observer.unobserve(container);
    };
  }, []);

  return (
    <Grid container ref={containerRef}>
      <Grid
        item
        sx={{
          boxShadow: DEFAULT_TAB_UNDERLINE,
          flexGrow: 1,
        }}
        {...{ ...props, component: "div" }}
      />

      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          flexGrow: 1,
          mt: isWrapped ? "10px" : 0,
          boxShadow: isWrapped ? TAB_WITH_NO_UNDERLINE : DEFAULT_TAB_UNDERLINE,
        }}
      >
        {selectedTabActionButtons}
      </Grid>
    </Grid>
  );
}

/** Returns the name of a tab for event-tracking based on its trackingTabName or if not provided the label */
function getTrackingTabName(
  label: ReactNode,
  trackingTabName?: string
): string {
  if (trackingTabName) {
    return trackingTabName;
  }

  return typeof label === "string" ? label : "";
}
